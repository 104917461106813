<template>
  <div class="home">
    <div class="description info-box">
      <h1 class="description-title info-box-title">Beschreibung</h1>
      <hr class="info-box-line" />
      <p class="info-box-text">
        Es handelt sich hierbei um einen Shop mit Empfehlungssystem. Dabei werden die 
        Interessen des Benutzers anhand dem was er sich ansieht berechnet und dann die 
        Produkte dementsprechend sortiert. So werden die Produkte je nach dem wie gut 
        sie zum Kunden passen weiter oben oder unten angezeigt.
        <br>
         
        <br>
        Zuerst wird nach dem am häufigsten angesehenem Style, dann nach der Kategorie 
        und dann nach Angebot und Nachhaltigkeit sortiert
      </p>
    </div>
    <div class="stats info-box">
      <h1 class="stats-title info-box-title">Nutzerprofil</h1>
      <hr class="info-box-line" />
      <div class="stats-clicked">
        <h2 class="stats-box-title">Angesehen</h2>
        <hr class="stats-box-line" />
        <p class="info-box-text info-box-text-stats">
          Gesamt: {{ $store.state.user.clicked.total }}
          <br />
          Angebote: {{ $store.state.user.clicked.sale }}
          <br />
          Nachhaltig: {{ $store.state.user.clicked.sustainable }}
          <br />
          Hosen: {{ $store.state.user.clicked.pants }}
          <br />
          Oberteile: {{ $store.state.user.clicked.shirts }}
          <br />
          Casual: {{ $store.state.user.clicked.casual }}
          <br />
          Vintage: {{ $store.state.user.clicked.vintage }}
          <br />
          Military: {{ $store.state.user.clicked.military }}
        </p>
      </div>
      <div class="stats-interests">
        <h2 class="stats-box-title">Interessen</h2>
        <hr class="stats-box-line" />
        <p class="info-box-text info-box-text-stats">
          Nachhaltigkeit: {{ Math.round($store.state.user.interests.sustainable * 100) }}%
          <br>
          Kategorien:
          <ul class="info-box-text-list">
            <li>Hosen: {{ Math.round($store.state.user.interests.pants * 100) }}%</li>
            <li>Oberteile: {{ Math.round($store.state.user.interests.shirts * 100) }}%</li>
          </ul>
          Style:
          <ul class="info-box-text-list">
            <li>Casual: {{ Math.round($store.state.user.interests.casual * 100) }}%</li>
            <li>Vintage: {{ Math.round($store.state.user.interests.vintage * 100) }}%</li>
            <li>Military: {{ Math.round($store.state.user.interests.military * 100) }}%</li>
          </ul>
        </p>
      </div>
    </div>
    <img src="img/frame.png" alt="img/frame.png" class="frame" />
    <div class="phone">
      <img
        src="img/status-bar.png"
        alt="img/status-bar.png"
        class="status-bar"
      />
      <div class="page">
        <Nav />
        <Home v-if="$store.state.page == 0"/>
        <Product v-if="$store.state.page == 1"/>
      </div>
      <div class="home-button"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Home from "@/components/Home.vue";
import Product from "@/components/Product.vue";

export default {
  name: "Phone",
  components: {
    Nav,
    Home,
    Product,
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  width: 125vh;
  .info-box {
    position: absolute;
    width: 75vh;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3vh;
    .info-box-title {
      text-align: left;
      font-size: 2.5vh;
      font-weight: 500;
      margin-top: 2vh;
      padding-left: 3.5vh;
      margin-bottom: 0;
      color: #eee;
    }
    .info-box-line {
      width: 92%;
      height: 0.15vh;
      border: none;
      background: #aaa;
    }
    .info-box-text {
      text-align: left;
      font-size: 1.75vh;
      font-weight: 400;
      margin-top: 2vh;
      padding-left: 3.5vh;
      color: #eee;
    }
    .info-box-text-stats {
      padding-left: 0.5vh;
      line-height: 4vh;
      .info-box-text-list {
        margin-top: 0;
        padding-left: 3vh;
        line-height: 3.25vh;
      }
    }
  }
  .description {
    height: 25.75vh;
  }
  .stats {
    bottom: 0;
    height: 49vh;
    .stats-clicked {
      position: absolute;
      left: 3vh;
      width: 35%;
    }
    .stats-interests {
      position: absolute;
      right: 3vh;
      width: 50%;
    }
    .stats-box-line {
      width: 100%;
      height: 0.1vh;
      border: none;
      background: #aaa;
    }
    .stats-box-title {
      text-align: left;
      font-size: 2vh;
      font-weight: 400;
      margin-top: 2vh;
      margin-bottom: 0;
      padding-left: 0.5vh;
      color: #eee;
    }
  }
  .frame {
    position: absolute;
    right: 0;
    height: 80vh;
    z-index: 999;
    pointer-events: none;
  }
  .phone {
    user-select: none;
    position: absolute;
    top: 2vh;
    right: 2.5vh;
    height: 76vh;
    width: 35vh;
    background: #fff;
    border-radius: 4vh;
    .status-bar {
      position: sticky;
      z-index: 2;
      width: 100%;
    }
    .page {
      margin-top: -2%;
      height: 95%;
      width: 100%;
    }
    .home-button {
      position: absolute;
      bottom: 1%;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 0.75%;
      background: rgb(10, 10, 10);
      border-radius: 100vh;
    }
  }
}
</style>