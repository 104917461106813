import Vue from "vue";
import VueRouter from "vue-router";
import Phone from "../views/Phone.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Phone",
    component: Phone,
}, ];

const router = new VueRouter({
    routes,
});

export default router;