import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    page: 0,
    user: {
      clicked: {
        total: 0,
        sale: 0,
        sustainable: 0,
        casual: 0,
        vintage: 0,
        military: 0,
        pants: 0,
        shirts: 0,
      },
      interests: {
        sustainable: 0.5,
        casual: 0.33,
        vintage: 0.33,
        military: 0.33,
        pants: 0.5,
        shirts: 0.5,
      },
    },
    products: [
      {
        label: "Kapuzensweatshirt mit Komfortschnitt",
        img: "shirt01.jpg",
        sale: true,
        price: 23.99,
        sustainable: true,
        category: "shirts",
        style: "vintage",
      },
      {
        label: "Jogginghose mit Bikerelementen",
        img: "pants01.jpg",
        sale: true,
        price: 29.99,
        sustainable: false,
        category: "pants",
        style: "military",
      },
      {
        label: "T-Shirt im Melange Look",
        img: "shirt07.jpg",
        sale: false,
        price: 11.99,
        sustainable: false,
        category: "shirts",
        style: "casual",
      },
      {
        label: "Classic Fit Stretch-Jeans, Straight",
        img: "pants04.jpg",
        sale: false,
        price: 19.99,
        sustainable: false,
        category: "pants",
        style: "casual",
      },
      {
        label: "Regular Fit Jeans, Straight",
        img: "pants05.jpg",
        sale: false,
        price: 19.99,
        sustainable: false,
        category: "pants",
        style: "casual",
      },
      {
        label: "Regular Fit Cargo-Hose, Straight",
        img: "pants03.jpg",
        sale: false,
        price: 24.99,
        sustainable: false,
        category: "pants",
        style: "military",
      },
      {
        label: "Regular Fit Stretch-Jeans, Straight",
        img: "pants06.jpg",
        sale: false,
        price: 23.99,
        sustainable: false,
        category: "pants",
        style: "vintage",
      },
      {
        label: "Kapuzen-Sweatjacke",
        img: "shirt02.jpg",
        sale: false,
        price: 27.99,
        sustainable: false,
        category: "shirts",
        style: "military",
      },
      {
        label: "Kapuzensweatshirt, Slim Fit",
        img: "shirt03.jpg",
        sale: false,
        price: 14.99,
        sustainable: false,
        category: "shirts",
        style: "military",
      },
      {
        label: "Pullover mit V-Ausschnitt",
        img: "shirt08.jpg",
        sale: false,
        price: 19.99,
        sustainable: true,
        category: "shirts",
        style: "casual",
      },
      {
        label: "Pullover mit recycelter Baumwolle",
        img: "shirt06.jpg",
        sale: false,
        price: 19.99,
        sustainable: true,
        category: "shirts",
        style: "casual",
      },
      {
        label: "Sportliches Sweatshirt",
        img: "shirt05.jpg",
        sale: true,
        price: 11.99,
        sustainable: false,
        category: "shirts",
        style: "casual",
      },
      {
        label: "Lederimitat-Jacke",
        img: "shirt04.jpg",
        sale: false,
        price: 55.99,
        sustainable: false,
        category: "shirts",
        style: "vintage",
      },
      {
        label: "Loose Fit Stretch-Cord-Cargohose",
        img: "pants02.jpg",
        sale: false,
        price: 22.99,
        sustainable: false,
        category: "pants",
        style: "vintage",
      },
    ],
    selectedProduct: {},
  },
  mutations: {
    showProduct: (state, item) => {
      state.selectedProduct = item;
      state.page = 1;

      const { category, style, sale, sustainable } = item;
      const { interests, clicked } = state.user;

      // save product data in user profile
      clicked.total++;
      clicked[category]++;
      clicked[style]++;
      if (sale) clicked.sale++;
      if (sustainable) clicked.sustainable++;

      // calculate interests
      for (const interest in interests) {
        interests[interest] = clicked[interest] / clicked.total;
      }
    },
  },
  getters: {
    getRecommended: (state) => {
      // filter products from interests
      let filteredProducts = state.products;

      const sort = (property, advanced) => {
        filteredProducts.sort((a, b) => {
          if (advanced) {
            return (
              state.user.interests[b[property]] -
              state.user.interests[a[property]]
            );
          } else {
            return b[property] - a[property];
          }
        });
      };

      sort("sustainable", false);
      sort("sale", false);
      sort("category", true);
      sort("style", true);

      return filteredProducts;
    },
  },
});
